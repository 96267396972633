import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { User } from '~models/user/user.model';
import { UserService } from '~services/user/user.service';

@Injectable()
export class HelpScoutService {
  constructor(
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  /* eslint-disable */
  initHelpScout(): void {
    // @ts-ignore
    // prettier-ignore
    !(function (e, t, n) { function a() { const e = t.getElementsByTagName('script')[0], n = t.createElement('script'); (n.type = 'text/javascript'), (n.async = !0), (n.src = 'https://beacon-v2.helpscout.net'), e.parentNode.insertBefore(n, e); } if (((e.Beacon = n = function (t: any, n: any, a: any) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }); }), (n.readyQueue = []), 'complete' === t.readyState)) return a(); e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1); })(window, document, window.Beacon || function () { });

    const locale = localStorage.getItem('NG_TRANSLATE_LANG_KEY') || navigator.language;
    if (locale !== undefined && locale.match(/^fr/i) !== null) {
      this.initBeacon('fr');
      this.translateService.use('fr');
    } else {
      this.initBeacon('en');
      this.translateService.use('en');
    }

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.destroyBeacon();
      this.initBeacon(event.lang as 'fr' | 'en');
    });

    this.userService.waitForAuthenticated().then((user) => {
      this.identifyUser(user);
    });
  }

  initBeacon(lang: 'fr' | 'en'): void {
    if (!('Beacon' in window)) {
      return;
    }

    if (lang === 'fr') {
      window.Beacon('init', 'ee5ecbf2-43f1-4558-b358-48b3ec9d03b0');
    } else {
      window.Beacon('init', '1e337330-5ffc-4dda-a7e1-e610600758e8');
    }

    window.Beacon('config', {
      display: {
        horizontalOffset: 10,
        verticalOffset: 10
      }
    });
  }

  initBeaconWithCurrentLang(): void {
    this.initBeacon(this.translateService.currentLang as 'fr' | 'en');
  }

  destroyBeacon(): void {
    if (!('Beacon' in window)) {
      return;
    }

    window.Beacon('destroy');
  }

  identifyUser(user: User): void {
    if (!('Beacon' in window)) {
      return;
    }

    window.Beacon('identify', {
      name: user.firstname,
      email: user.email
    });
  }

  pageChanged(): void {
    if (!('Beacon' in window)) {
      return;
    }

    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title
    });
    window.Beacon('suggest');
  }
}
